<script setup lang="ts">
import { register } from 'swiper/element/bundle';
import type { CmsBlock } from '@shopware-pwa/types';

register();

const props = defineProps<{
  content: CmsBlock;
  modelVersion: number;
  lightMode: boolean;
}>();

// const teasers = props.content.slots;
const variants = [
  [
    {
      id: '1',
      media: {
        url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/Picture_Frau-min.png',
      },
      name: 'Rocketgirl',
      ctaLabel: 'Zur Rocketgirl Kollektion',
      url: '/Highlights/Rocketgirl/',
    },
    {
      id: '2',
      media: {
        url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/Picture_Mann-min.png',
      },
      name: 'Spaceman',
      ctaLabel: 'Zur Spaceman Kollektion',
      url: '/Highlights/Spaceman/',
    },
  ],
  [
    {
      id: '1',
      media: {
        url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/versus/sierra.jpg',
      },
      name: 'Sierra',
      ctaLabel: 'Funktionswäsche für Sie',
      url: '/Shop/Damen/Funktionswaesche/',
    },
    {
      id: '2',
      media: {
        url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/versus/arctict.jpg',
      },
      name: 'Arctic',
      ctaLabel: 'Funktionswäsche für Ihn',
      url: '/Shop/Herren/Funktionswaesche/',
    },
  ],
];
const teasers = variants[props.modelVersion];
</script>
<template>
  <div
    class="lg:px-15 hover-vs-section overflow-visible px-5 py-10 lg:py-20"
    :class="lightMode ? 'text-dark bg-white' : 'bg-dark text-white'"
  >
    <div class="flex flex-col gap-2">
      <div class="flex justify-between pb-1">
        <h2>{{ props.content.name }}</h2>
      </div>
    </div>
    <div class="versus-wrapper">
      <div
        class="max-w-screen grid grid-cols-[1fr_min-content_1fr] overflow-visible py-8 lg:py-0"
      >
        <div class="image-left relative">
          <NuxtImg
            class="w-full object-cover"
            :src="teasers[0].media.url"
            :alt="teasers[0].name"
          />
          <div
            class="box-label -lg:mb-5 absolute bottom-1/2 right-0 -mb-2 pr-6 text-xl font-bold lg:text-4xl"
          >
            {{ teasers[0].name }}
          </div>
        </div>
        <div class="center-col flex w-fit items-center">
          <div class="versus-label text-uppercase px-4 text-xl">vs.</div>
        </div>
        <div class="image-right relative">
          <NuxtImg
            class="relative w-full object-cover"
            :src="teasers[1].media.url"
            :alt="teasers[1].name"
          />
          <div
            class="box-label -lg:mb-5 absolute bottom-1/2 left-0 -mb-2 pl-6 text-xl font-bold lg:text-4xl"
          >
            {{ teasers[1].name }}
          </div>
        </div>
      </div>
      <!-- Button Row -->
      <div class="mt-8 flex grid-cols-2 flex-col gap-4 lg:grid lg:gap-0">
        <RouterLink
          :to="teasers[0].url"
          class="btn mx-auto block w-full px-5 lg:w-fit lg:px-20"
          :class="lightMode ? 'btn-primary' : 'btn-secondary'"
        >
          {{ teasers[0].ctaLabel }}
        </RouterLink>
        <RouterLink
          :to="teasers[1].url"
          class="btn mx-auto block w-full px-5 lg:w-fit lg:px-20"
          :class="lightMode ? 'btn-primary' : 'btn-secondary'"
        >
          {{ teasers[1].ctaLabel }}
        </RouterLink>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.hover-vs-section {
  --slide-width: 5rem;
  transition: all 0.5s ease;

  .image-left,
  .image-right,
  .center-col,
  .box-label {
    transition: all 0.5s ease;
  }

  .image-left {
    transform: translateX(-10%);
  }

  .image-right {
    transform: translateX(10%);
  }

  .center-col,
  .box-label {
    opacity: 0;
  }

  .image-left,
  .image-right {
    transition: all 0.5s ease;
  }

  @media screen and (min-width: 1080px) {
    &:hover {
      .image-left {
        transform: translateX(0);
      }

      .image-right {
        transform: translateX(0);
      }

      .center-col,
      .box-label {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .image-left {
      transform: translateX(-10%) scale(1.2);
    }

    .image-right {
      transform: translateX(10%) scale(1.2);
    }

    .center-col,
    .box-label {
      opacity: 1;
    }
  }
}
</style>
